<template>
  <div class="P-filters ">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify neutral--text"
      />
    </div>

    <div class="P-input-block G-align-center justify-space-between">
      <ott-checkbox
        v-model="filterModel.allResellers"
        @updated="filterData('allResellers')"
        label="All"
        checkboxClass="me-2"
      />

      <div class="w-80">
        <ott-select
          v-model="filterModel.resellers"
          @emitFunc="filterData('resellers')"
          :clear="true"
          :items="resellersList"
          :multiple="true"
          :persistentHint="true"
          label="Select Resellers"
          selected-text="name"
        ></ott-select>
      </div>
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.courier"
        @emitFunc="filterData('courier')"
        @clearData="resetData('courier')"
        :items="couriersList"
        :clear="true"
        label="Courier"
        selected-text="name"
      />
    </div>

    <div class="P-input-block G-date-picker d-flex align-center">
      <DatePicker
        v-model="filterModel.date"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        is-range
        locale="en"
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Shipping Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('date')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Shipping End"
                    />
                    <span
                        @click="resetData('date')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttInput from "@/components/vuetifyComponents/OttInput";
import SelectedFiltersList from "./SelectedFiltersList";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

export default {
  name: "FilterData",
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  components: { OttInput, SelectedFiltersList, OttSelect, DatePicker, OttCheckbox },
  computed: {
    ...mapState({
      filterModel: (state) => state.shippingsModule.filterModel,
      filter: (state) => state.shippingsModule.filterData,
      shippingsList: (state) => state.shippingsModule.dataTable,
      resellersList: (state) => state.providers.resellersList,
    }),

    couriersList() {
      const list = [];
      let newObj = {};

      this.shippingsList.map((item) => {
        if (item?.selectedCourier) {
          if (item.selectedCourier.courier_id) {
            newObj = {
              name: item.selectedCourier.courier_name,
              value: item.selectedCourier.courier_id,
            }

            //check if not duplicated ids then push
            if (!list.some(i => i.value === item.selectedCourier.courier_id)) {
              list.push(newObj);
            }
          }
        }
      });

      return list;
    },
  },

  methods: {
    ...mapActions({
      getShippingsList: "shippingsModule/getShippingsList",
    }),

    ...mapMutations({
      filterShippings: "shippingsModule/filterShippings",
      setFilterPage: "shippingsModule/setFilterPage",
      sortColumn: 'shippingsModule/sortColumn',
    }),

    async filterData(type) {
      switch (type) {
        case "search":
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type);
          }
          break;

        case "allResellers":
          if (!this.filterModel.allResellers) {
            this.filterModel.resetCurrentData(type);
          } 
          break;

        case "resellers":
          if (!this.filterModel.resellers.length) {
            this.filterModel.resetCurrentData(type);
          } 
          break;

        case "courier":
          if (!this.filterModel.courier) {
            this.filterModel.resetCurrentData(type);
          }
          break;
      }

      this.setFilterPage(1)
      this.filterModel.getFilterCount();
      this.filterShippings();
      this.sortColumn();
      await this.getShippingsList(this.filter);
      this.$emit('resetSelectedList')
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterShippings()

      await this.getShippingsList(this.filter);
      this.$emit('resetSelectedList')
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/datePicker";

  .P-input-block {
    margin-bottom: 20px;
  }

  .P-provider-date {
    .P-date-icon {
      top : 20px;
    }
  }

  ::v-deep {
    .v-text-field__details,
    .v-messages.theme--light{
      display: none;
    }
    .v-input__slot,
    .v-input--dense>.v-input__control>.v-input__slot,
    .range-select {
      margin-bottom: 0;
    }
  }

  .w-80 {
    width: 80%;
  }
</style>
