<template>
  <div class="P-comments-part">
    <div class="mb-2">
      <title-component
        v-if="!commentsList.length && !showComponents" 
        :is-add="true"
        :click="() => showComponents = true" 
        title-text="Add Comment" 
      />

      <title-component
        v-if="showComponents"
        :isAdd="showAddIcon"
        :title-text="title"
        :click="plusButtonClick"
      />
    </div>
    <div v-if="showComponents">
      <manage-comments
        v-if="
          (!commentsList.length && !isShowForm) ||
          (commentsList.length && isShowForm) ||
          !commentsList.length
        "
        @isSaved="isShowForm = false"
        @cancelAddEdit="cancelAddEdit"
        :isEdit="isEdit"
      />
      <comments-list v-else @edit="edit" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import ManageComments from "./components/ManageComments";
import CommentsList from "./components/CommentsList";

export default {
  name: "Comments",
  components: {
    TitleComponent,
    ManageComments,
    CommentsList,
  },
  data() {
    return {
      isShowForm: false,
      isEdit: false
    };
  },
  computed: {
    ...mapState({
      commentsList: (state) => state.comments.commentsList,
      showAllComponents: (state) => state.comments.showComponents
    }),
    
    showComponents: {
      set(value) {
        this.setData({ showComponents: value });
      },
      get() {
        return this.showAllComponents;
      },
    },

    showAddIcon() {
      if (
        (!this.commentsList.length && !this.isShowForm) ||
        (this.commentsList.length && this.isShowForm)
      ) {
        return false;
      } else {
        return true;
      }
    },

    title() {
      if (!this.commentsList.length || (this.isShowForm && !this.isEdit)) {
        return "Add Comment"
      } else if (this.isEdit) {
        return "Edit Comment"
      } else {
        return "Comments"
      }
    },
  },

  watch: {
    commentsList(comments) {
      if (!comments.length) {
        this.setData({ showComponents: false });
      } else {
        this.setData({ showComponents: true });
      }
    }
  },

  created() {
    if (this.commentsList.length) {
      this.setData({ showComponents: true });
    } else {
      this.setData({ showComponents: false });
    }
  },

  methods: {
    ...mapMutations({
      setData: "comments/setData",
      resetFormData: "comments/resetFormData",
      setFormData: "comments/setFormData",
    }),

    plusButtonClick() {
      this.isShowForm = true;
      this.resetFormData();
    },

    edit(comment) {
      this.setData({ selectedCommentId: comment.id })
      this.isEdit = true
      let commentTime = comment.reminderDate ?  this.$moment(comment.reminderDate).format('HH:mm') : null;
      this.setFormData({ comment: comment.comment });
      this.setFormData({ reminderDate: comment.reminderDate });
      this.setFormData({ sendNotification: comment.sendNotification });      
      this.setFormData({ isPrivate: comment.isPrivate });
      this.setFormData({ time: commentTime });
      this.isShowForm = true;
    },

    cancelAddEdit() {
      this.isShowForm = false
      this.isEdit = false

      if (!this.commentsList.length) {
        this.setData({ showComponents: false });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.P-comments-part {
  .v-text-field__details {
    display: none;
  }
}

.G-date-picker {
  position: relative;

  & .P-date-picker {
    width: 35%;
  }

  .ott-date-picker {
    width: 100%;
  }
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
}
</style>