<template>
  <div class="P-list-part">
    <div class="flex-column G-scroll-list">
      <table class="P-list-table my-4">
        <tr class="pb-4">
          <th class="pb-4 px-1 P-list-headers" v-for="(item, key) of headers" :key="key">
            {{ item }}
          </th>
        </tr>
        <template v-for="(item, index) of commentsList">
          <tbody :key="index" >
            <tr class="P-hovered">
              <td class="text-center P-list-items px-1 py-3">
                <span class="mdi mdi-bell P-notification-icon" /> 
                {{ item.updatedAt }}
              </td>
              <td class="text-center P-list-items px-1 py-3">
                <span v-if="item.reminderDateFormated && item.sendNotification">{{ item.reminderDateFormated }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center P-list-items px-1 py-3">
                {{ item.user.firstname + " " + item.user.lastname }}  
              </td>
              <td class="text-center P-list-items px-1 py-3">
                <span v-if="item.notification?.updateUser">{{ item.notification.updateUser.firstname + ' ' + item.notification.updateUser.lastname }}</span>
                <span v-else>-</span>
              </td>
              <td class="text-center px-1 py-3">
                <span v-if="item.isPrivate" class="mdi mdi-check green--text" />
                <span v-else class="mdi mdi-close red--text" />
              </td>
              <td class="text-center px-1 py-3">
                <div class="P-actions">
                  <v-menu left content-class="P-actions-list">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark icon v-bind="attrs" v-on="on">
                        <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="permissionIs('updateComment')"
                        class="P-list"
                        @click="$emit('edit', item)"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
            <tr>
              <td class="P-list-items px-1 py-3" colspan="6">
                <div class="P-comment-cont text-center" v-html="getComment(item.comment)" />
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <image-modal
      v-if="isShowImage"
      :isOpenModal="isShowImage"
      :imageSrc="imageSrc"
      @closeModal="isShowImage = false"
      modal-title="Comment Image"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ImageModal from "@/components/ImageModal";

export default {
  name: "CommentsList",
  components: { ImageModal },
  mixins: [ DateFormatMixin ],
  data() {
    return {
      headers: ["Date Time", "Reminder Date", "User", "Notification Reader", "Private", "Actions"],
      accardion: null,
      imageSrc: "",
      isShowImage: false,
      commentImages: [],
    };
  },
  computed: {
    ...mapState({
      commentsList: (state) => state.comments.commentsList,
      searchedClientId: (state) => state.generalSearch.searchedClientId,
    }),
  },

  mounted() {
    setTimeout(() => {
      this.getCommentImage();
    },0)
  },

  methods: {
    ...mapActions({
      deleteComment: "comments/deleteComment",
    }),

    ...mapMutations({
      setData: "comments/setData",
    }),

    getComment(comment) {
      let txt = comment.replaceAll("&lt;", "<");
      return txt;
    },

    getCommentImage() {
      this.commentImages = document.querySelectorAll(".P-comment-cont img");
      this.commentImages.forEach((img) => {
        img.addEventListener("click", (e) => {
          e.stopPropagation();
          e.preventDefault();
          this.imageSrc = img.getAttribute("src");
          this.isShowImage = true;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/simpleTable";

.P-list-table {
  width: 100%;
  border-collapse: collapse;

  & > tr {
    border-bottom: 1px solid $neutral-colorDM;
  }

  th {
    line-height: 15px;
    text-align: center;
  }

  td {
    font-size: 15px;
    line-height: 24px;
  }

  .P-list-headers {
    font-size: $txt12;
  }

  .P-list-items {
    font-size: $txt14;
  }
}

.P-notification-icon {
  color: #499630;
  text-shadow: 0 0 1px black;
}

.P-hovered {
  position: relative;
  border-bottom: 1px solid $neutral-colorDM;

  & > tr:nth-child(1) {
    border-bottom: 1px dashed #d3d3d3;
  }

  &:after {
    width: 2px;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    transition: $transition;
    background-color: $primary-color;
    content: "";
  }

  &:hover {
    &:after {
      height: 100%;
    }

    & td {
      background-color : $neutral-color7;
    }
  }
}

 .theme--dark {
  & th {
    background-color  : $dark_light;  
  }

  .P-hovered {
    &:hover {
      & td {
        background-color : transparent !important;
      }
    }
  }
}
</style>

<style lang="scss">
.P-comment-cont {
  word-break: break-word;

  img,
  video,
  iframe {
    width: 100%;
    cursor: pointer;
  }
}
</style>
