<template>
  <div class="position-relative shippings">
    <div
      class="G-page-component"
      :class="{
        'P-filter-open': isOpenFilter,
        'pa-0': $route.name === 'Search',
      }"
    >
      <div
        :class="{ 'mb-0': $route.name === 'Search' }"
        class="G-page-title-block G-flex G-justify-between G-align-center"
      >
        <div class="G-component-title d-flex">
          <h3
            :class="{ 'P-small-header': $route.name === 'Search' }"
            class="secondary--text"
          >
            Shippings
          </h3>
        </div>
      </div>
      <div v-if="$route.name !== 'Search' || ($route.name === 'Search' && clientData?.provider?.hasValidShippingGateway)" class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <ott-checkbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on">
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            v-if="showColumnConfig"
            :column-config="columnConfigData"
            :is-default="isDefault"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @hoverColumnConfig="hoverColumnConfig"
            @leaveColumnConfig="leaveColumnConfig"
            class="p-l-10"
          />
        </div>
        <div v-if="$route.name !== 'Search'">
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        v-if="$route.name !== 'Search' || ($route.name === 'Search' && clientData?.provider?.hasValidShippingGateway)"
        class="G-component-body page-background"
      >
        <div v-if="$route.name !== 'Search'" class="G-sub-block-title G-justify-between align-center">
          <div v-if="$route.name !== 'Search'">
            <h3 class="secondary--text">Shippings List</h3>
          </div>
          <transition name="fade">
            <div class="G-total-sum G-flex-center px-3 me-2" v-if="shippingBalance !== null">
              Shipping Balanace
              {{
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                }).format(shippingBalance)
              }}
            </div>
          </transition>
        </div>
        
        <OttUniversalTable
          v-if="(!loading && $route.name !== 'Search') || ($route.name === 'Search')"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="shippings.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="shippings.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        >
          <template slot-scope="props" slot="action">
            <!-- v-if="
              ((permissionIs('deleteShipping') ||
                permissionIs('updateShipping')) &&
                props.rowData.label_state === 'not_created' &&
                props.rowData.easyship_shipment_id) ||
              (props.rowData.label_state === 'printed' &&
                props.rowData.easyship_shipment_id) ||
              !props.rowData.easyship_shipment_id
            " -->
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon
                    :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item v-if="props.rowData.label_state === 'not_created' && props.rowData.easyship_shipment_id">
                      <v-list-item-title @click="generateLabelHandler(props.rowData)">Generate Label Easyship</v-list-item-title>
                  </v-list-item>
   
                  <v-list-item v-if="!props.rowData.easyship_shipment_id">
                      <v-list-item-title>Create on Easyship</v-list-item-title>
                  </v-list-item> -->
                <v-list-item @click.stop="openShippingModal(props.rowData)">
                  <v-list-item-title>View Information</v-list-item-title>
                </v-list-item>

                <!-- v-if="permissionIs('updateShipping') && props.rowData.label_state === 'printed' && props.rowData.easyship_shipment_id" -->
                <v-list-item v-if="props.rowData.shipment_state !== 'cancelled'"  @click="selectAction($event, props, 'cancel')">
                  <v-list-item-title>Cancel</v-list-item-title>
                </v-list-item>

                <v-list-item
                  @click="selectAction($event, props, 'delete')"
                  v-if="
                    permissionIs('deleteShipping') &&
                    !props.rowData.easyship_shipment_id
                  "
                >
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-model="rowsPerPage"
          v-if="shippings.shippingsResult && !loading"
          :data-count="shippings.dataCount"
          :available-data-count="shippings.dataTable.length"
          :current-page="shippings.currentPage"
          :page-limit="shippings.totalPages"
          @update="updateLimit"
          @updatePage="updateShippings"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      v-if="$route.name !== 'Search'"
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>

    <PageLoader v-if="(shippings.isTableLoading || loading) && $route.name !== 'Search'" />

    <WarningModal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      close-btn-text="Close"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal"
      @btnFunction="warningModalBtnFunction"
    />

    <ott-dialog
      v-if="$route.name !== 'Search' || ($route.name === 'Search' && clientData?.provider?.hasValidShippingGateway)"
      :is-open="isOpenShippingModal"
      :show-footer="false"
      :modal-title="shippingModalTitle"
      @outside="closeShippingModal"
      @resetDataModal="closeShippingModal"
    >
      <template #modalBody>
        <div>
          <div class="d-flex align-center justify-space-between mx-4 mt-4">
            <ott-button-loader
              v-if="selectedShipping?.easyShipData?.tracking_page_url"
              @onClick="copyTrackingLink"
              :button-text="trackingUrlCopied ? 'Copied' : 'Copy Tracking Link'"
              class-name="w-max-content txt-13"
            />
            <div
              v-if="
                selectedShipping?.delivery_state !== '' &&
                selectedShipping?.shipment_state !== ''
              "
              class="G-align-center"
            >
              <span
                class="me-1 shipping-status-circle"
                :class="
                  selectedShipping?.delivery_state === 'technical_error'
                    ? 'background-red'
                    : selectedShipping?.delivery_state === 'voided' ||
                      selectedShipping?.delivery_state === 'canceled' ||
                      selectedShipping?.delivery_state === 'not_created'
                    ? 'background-black'
                    : selectedShipping?.delivery_state === 'printed' ||
                      selectedShipping?.delivery_state === 'delivered'
                    ? 'background-green'
                    : 'background-black'
                "
              ></span>
              <h4 class="text-capitalize mb-0">
                {{
                  selectedShipping?.delivery_state.split("_").join(" ") ===
                  "delivered"
                    ? selectedShipping?.delivery_state.split("_").join(" ")
                    : selectedShipping?.shipment_state === "cancelled" &&
                      selectedShipping?.delivery_state === "not_created"
                    ? selectedShipping?.shipment_state
                    : selectedShipping?.delivery_state.split("_").join(" ")
                }}
              </h4>
            </div>
          </div>

          <div class="P-shipping-modal G-justify-center py-2 flex-wrap">
            <template v-for="(info, index) of selectedInformations">
              <div 
                v-if="Object.keys(info?.keys).length" 
                :key="index"
                :class="info.heading === 'Shipment Costs' ? 'w-64' : 'w-31'"
                class="info-cont ma-2 w-31 pa-2"
              >
               
                <div
                  v-if="
                    info.heading !== 'Insurance' &&
                    info.heading !== 'Shipment Costs'
                  "
                >
                  <h4 class="text-center secondary--text mb-3">
                    {{ info.heading }}
                  </h4>

                  <table class="w-100">
                    <tr v-for="(value, key) in info.keys" :key="key">
                      <td
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        {{ key.split("_").join(" ") }}
                      </td>
                      <td
                        v-if="Array.isArray(value)"
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                        v-html="
                          value.length && value.length > 1
                            ? value.join(', ')
                            : value.length === 1
                            ? value[0]
                            : !value.length
                            ? '-'
                            : ''
                        "
                      />
                      <td
                        v-if="!Array.isArray(value)"
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        {{
                          key === "phone"
                            ? value?.number
                            : key === "discount"
                            ? value?.amount
                            : value === null || value === ""
                            ? "-"
                            : value === false
                            ? "No"
                            : value === true
                            ? "Yes"
                            : value
                        }}
                      </td>

                      <!-- <td v-if="info.heading === 'Insurance'" class="pb-1 w-50 secondary--text text-capitalize txt-14">
                        <p class="mb-0">
                          No insurance was purchased for this shipment, 
                          and this courier does not provide any coverage 
                          in the event of lost or damaged parcels. 
                          <a href="https://support.easyship.com/hc/en-us/articles/360036401891-Uninsured-Shipments-How-to-File-a-Claim" target="_blank">Learn more</a>.
                        </p>
                      </td> -->
                    </tr>
                  </table>

                  <div class="G-justify-center">
                    <ott-button-loader
                      v-if="info.heading.includes('Destination')"
                      @onClick="generateLabelHandler(selectedShippingtId)"
                      button-text="Create Return Label"
                      class-name="w-max-content mt-4 return-label"
                    />
                  </div>
                  <!--  -->
                  <!-- :is-loading="isLoading" -->
                </div>

                <div v-if="info.heading === 'Insurance'">
                  <div class="d-flex align-center justify-center mb-3">
                    <h4 class="text-center secondary--text">
                      {{ info.heading }}
                    </h4>
                    <div
                      v-if="info.heading === 'Insurance'"
                      :class="
                        selectedShipping.isPremiumShipping
                          ? 'background-green'
                          : 'background-red'
                      "
                      class="info-cont__insurance ms-1"
                    >
                      <span v-if="selectedShipping.isPremiumShipping"
                        >Premium Insurance</span
                      >
                      <span v-else>Not Insured</span>
                    </div>
                  </div>
                  <table
                    v-if="selectedShipping.easyShipData.insurance.is_insured"
                    class="w-100"
                  >
                    <tr v-for="(value, key) in info.keys" :key="key">
                      <td
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        {{ key.split("_").join(" ") }}
                      </td>
                      <td
                        v-if="!Array.isArray(value)"
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        {{
                          value === null || value === ""
                            ? "-"
                            : value === false
                            ? "No"
                            : value === true
                            ? "Yes"
                            : value
                        }}
                      </td>
                    </tr>
                  </table>

                  <p v-else class="mb-0">
                    No insurance was purchased for this shipment, and this courier
                    does not provide any coverage in the event of lost or damaged
                    parcels.
                    <a
                      href="https://support.easyship.com/hc/en-us/articles/360036401891-Uninsured-Shipments-How-to-File-a-Claim"
                      target="_blank"
                      >Learn more</a
                    >.
                  </p>
                </div>

                <div v-if="info.heading === 'Shipment Costs'">
                  <div class="d-flex align-center justify-center mb-3">
                    <h4 class="text-center secondary--text">
                      {{ info.heading }}
                    </h4>
                  </div>
                  <table class="w-100 info-cont__costs-table">
                    <tr v-for="(value, key) in info.keys" :key="key">
                      <td
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        <b v-if="key === 'total_cost'">
                          {{ key.split("_").join(" ") }}
                        </b>
                        <span v-else>{{ key.split("_").join(" ") }}</span>
                      </td>

                      <td
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        <b>{{ value === null || value === "" ? "-" : value }}</b>
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="pb-1 w-50 secondary--text text-capitalize txt-14"
                      >
                        <b>Total Paid to Easyship</b>
                      </td>
                      <td colspan="2" class="txt-14">
                        <b v-if="typeof selectedShipping?.selectedCourier ?.rates_in_origin_currency?.total_charge === 'number'">
                          {{
                            new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2,
                            }).format(selectedShipping?.selectedCourier?.rates_in_origin_currency?.total_charge)
                          }}
                        </b>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </template>

            <div
              v-if="selectedShipping?.easyShipData?.parcels"
              class="info-cont w-100 ma-2 pa-2"
            >
              <div class="G-justify-between">
                <div class="secondary--text text-capitalize txt-14">
                  <b>Box</b>
                  {{
                    selectedShipping.easyShipData.parcels[0].box.name
                      ? selectedShipping.easyShipData.parcels[0].box.name
                      : "Custom Box"
                  }}
                </div>

                <div class="secondary--text txt-14">
                  Dimensions: {{ selectedShipping.boxes[0].length }} x
                  {{ selectedShipping.boxes[0].width }} x
                  {{ selectedShipping.boxes[0].height }} in
                </div>

                <div class="secondary--text txt-14">
                  Weight: {{ selectedShipping?.boxes[0].weight.toFixed(3) }} lb
                </div>

                <div class="secondary--text txt-14">
                  Item Count: {{ selectedShipping?.equipments.length }}
                </div>

                <div class="secondary--text txt-14">
                  Total Selling Price:
                  {{
                    typeof selectedShipping?.easyShipData?.total_customs_value === 'number'
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                        }).format(selectedShipping?.easyShipData?.total_customs_value)
                      : '-'
                  }}
                </div>
              </div>

              <table class="info-cont__parcels-table w-100 mt-3">
                <tr class="bg-neutral">
                  <td class="secondary--text txt-14 px-3 py-1">Item Type/HS Code</td>
                  <td class="secondary--text txt-14 px-3 py-1 description-column">Item Description</td>
                  <td class="secondary--text txt-14 text-center px-3 py-1">Company SKU</td>
                  <td class="secondary--text txt-14 text-center px-3 py-1">COO Dimensions (in)</td>
                  <td class="secondary--text txt-14 text-center px-3 py-1">Weight (lb)</td>
                  <td class="secondary--text txt-14 text-center px-3 py-1">Qty</td>
                  <td class="secondary--text txt-14 text-center px-3 py-1">Selling Price</td>
                </tr>

                <tr v-for="(item, itemIndex) in selectedShipping.easyShipData.parcels[0].items" :key="itemIndex">
                  <td class="secondary--text txt-14 px-3 py-1">
                    {{ item.category }}
                  </td>

                  <td class="secondary--text txt-14 px-3 py-1">
                    {{ item.description }}
                  </td>

                  <td class="secondary--text txt-14 px-3 py-1 text-center">
                    <div class="G-align-center">
                      <img src="https://app.easyship.com/assets/images/dashboard/create-shipment-main-modal/3ff14591.icon-battery.svg" title="Contains battery" class="me-2" />
                      <span>{{ item.sku }}</span>
                    </div>
                  </td>

                  <td class="secondary--text txt-14 px-3 text-center py-1">
                    {{ item.dimensions.length }} x {{ item.dimensions.width }} x {{ item.dimensions.height }}
                  </td>

                  <td class="secondary--text txt-14 px-3 text-center py-1">
                    {{ item.actual_weight.toFixed(3) }}
                  </td>

                  <td class="secondary--text txt-14 px-3 text-center py-1">
                    {{ item.quantity }}
                  </td>

                  <td class="secondary--text txt-14 px-3 text-center py-1">
                    <span>
                      {{
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: item.declared_currency,
                          minimumFractionDigits: 2,
                        }).format(item.origin_customs_value)
                      }}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </template>
    </ott-dialog>

    <error-message-container
      v-if="$route.name === 'Search' && !clientData?.provider?.hasValidShippingGateway"
      message="The client's provider not has a valid shipping gateway."
      classes="w-max-content"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// mixins
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import PageLoader from "@/components/pageLoader/PageLoader";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

// modals
import WarningModal from "@/components/WarningModal";

export default {
  name: "Shippings",
  components: {
    OttDialog,
    PageLoader,
    NoDataInTable,
    OttPaginationForTable,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttSelect,
    OttRadio,
    OttCheckbox,
    OttButton,
    OttButtonLoader,
    FilterData,
    WarningModal,
    ErrorMessageContainer
  },
  mixins: [ OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin ],
  data() {
    return {
      resellers: [],
      defaultColumnConfigData: [],
      columnConfigData: [],
      actionItems: [
        { title: "Edit", value: "edit" },
        { title: "Delete", value: "delete" },
        { title: "cancel", value: "cancel" },
      ],
      shippingId: null,
      warningModalType: "",
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      isOpenShippingModal: false,
      selectedShipping: null,
      selectedInformations: [],
      trackingUrlCopied: false,
      loading: false
    };
  },
  async created() {
    // this.filterShippings()
    if (this.$route.name !== 'Search') {
      this.loading = true
      await this.getResellersList()
      await this.getShippingsSettings()
    }
    this.createColumnConfigData()
    this.sortColumn();
    this.shippings
    if (this.$route.name !== 'Search') {
      await this.getShippingsList(this.shippings.filterData).finally(() => this.loading = false);
    }
    this.resetSelectedList()
  },

  destroyed() {
    this.setFilterPage(1)
    this.shippingsResetState()
  },
  computed: {
    ...mapState({
      shippings: (state) => state.shippingsModule,
      dataTable: (state) => state.shippingsModule.dataTable,
      isDefault: (state) => state.shippingsModule.isDefault,
      filter: (state) => state.shippingsModule.filter,
      filterModel: (state) => state.shippingsModule.filterModel,
      showWarningModal: (state) => state.shippingsModule.showWarningModal,
      selectedShippingtId: (state) => state.shippingsModule.selectedShippingtId,
      defaultSettings: (state) => state.shippingsModule.defaultSettings,
      updateColumnSettingsCommit: (state) => state.shippingsModule.updateColumnSettingsCommit,
      updateColumnsFromBack: (state) => state.shippingsModule.updateColumnsFromBack,
      showColumnConfig: (state) => state.shippingsModule.showColumnConfig,
      resellersList: (state) => state.providers.resellersList,
      clientData: (state) => state.clientsModule.client,
      shippingBalance: (state) => state.shippingsModule.shippingBalance,
    }),

    shippingModalTitle() {
      return `Shipment ID: <a class="color-white" href="${this.selectedShipping?.easyShipData?.tracking_page_url}" target="_blank">${this.selectedShipping?.easyship_shipment_id}</a>`;
    },

    warningModalHeading() {
      return `${this.warningModalBtnText} Shipping ?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> selected Shipping! <br />  If you're not sure, you can cancel this operation.`;
    },

    defaultColumnConfigs() {
      return [
        {
          title: "Easy ID",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "easyship_shipment_id",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          sortData: {
            isSort: true,
            sortKey: "easyship_shipment_id",
            sortValue: "",
          },
          cellView: (row) => {
            if (row.easyship_shipment_id) {
              return `<p class="G-text-table">${row.easyship_shipment_id} <span>(${row.shipment_state})</span></p>`;
            } else {
              return "-";
            }
          },
        },
        {
          title: "Label state",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "label_state",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          sortData: {
            isSort: true,
            sortKey: "label_state",
            sortValue: "",
          },
          cellView: (row) => {
            if (row.label_state) {
              let html = `<div class="G-align-center">
                <span class="me-1 shipping-status-circle ${
                  row.label_state === "technical_error"
                    ? "background-red"
                    : row.label_state === "voided" ||
                      row.label_state === "canceled" ||
                      row.label_state === "not_created"
                    ? "background-black"
                    : row.label_state === "printed" ||
                      row.delivery_state === "delivered"
                    ? "background-green"
                    : "background-black"
                }"></span>
                <p class="text-capitalize shipping-status-text">${row.label_state
                  .split("_")
                  .join(" ")}`;
              if (row.shipping_documents?.length) {
                html += `<a target="_blank" href="${
                  row.shipping_documents[0].url.split("/label")[0]
                }"> PDF</a>`;
              }
              html += `</p></div>`;
              return html;
            } else {
              return "-";
            }
          },
        },
        {
          title: "Sender & Receiver",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "senderReceiver",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          className: "P-sender-data",
          cellView: (row) =>
            `<p class="G-text-table G-align-center">
              <img src="https://flagcdn.com/${row.shipTo.country.toLowerCase()}.svg" class="small-logo me-2" /> 
              <span>${row.shipTo.firstname} ${row.shipTo.lastname}</span>
            </p>`,
        },
        {
          title: "Courier",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "courier",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          className: "P-courier-data",
          cellView: (row) =>
            `<p class="G-text-table d-flex align-center">
              <img src="https://assets.easyship.com/app/courier-logos/${row.selectedCourier?.courier?.logo_url}-mini.svg" class="small-logo me-2"/>
              <span class="text-14">${row.selectedCourier?.courier_name}</span>
            </p>`,
        },
        {
          title: "Tracking",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "tracking",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          sortData: {
            isSort: true,
            sortKey: "tracking",
            sortValue: "",
          },
          cellView: (row) => {
            if (row.trackings.length) {
              return `<a class="G-text-table" target="_blank" href="${row.tracking_page_url}">
                        ${row.trackings[0].tracking_number}
                      </a>`;
            } else {
              return "-";
            }
          },
        },
        {
          title: "Remarks",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "remark",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          sortData: {
            isSort: true,
            sortKey: "remarks",
            sortValue: "",
          },
          cellView: (row) => {
            if (row.insurance?.is_insured !== undefined) {
              if (row.insurance?.is_insured) {
                return `<img src="https://assets.easyship.com/app/insurance/shield-green.svg" title="You have insured this shipment." width="23" />`;
              }
            } else {
              return "-";
            }
          },
        },
        {
          title: "Delivery State",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "delivery_state",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          sortData: {
            isSort: true,
            sortKey: "delivery_state",
            sortValue: "",
          },
          cellView: (row) => {
            if (row.delivery_state) {
              return `
                <div class="G-align-center">
                  <span class="me-1 shipping-status-circle ${
                    row.delivery_state === "technical_error"
                      ? "background-red"
                      : row.delivery_state === "voided" ||
                        row.delivery_state === "canceled" ||
                        row.delivery_state === "not_created"
                      ? "background-black"
                      : row.delivery_state === "printed" ||
                        row.delivery_state === "delivered"
                      ? "background-green"
                      : "background-black"
                  }"></span> 
                 <p class="text-capitalize shipping-status-text">
                  ${
                    row.delivery_state.split("_").join(" ") === "delivered"
                      ? row.delivery_state.split("_").join(" ")
                      : row.shipment_state === "cancelled" &&
                        row.delivery_state === "not_created"
                      ? row.shipment_state
                      : row.delivery_state.split("_").join(" ")
                  }
                 </p>
                </div>`;
            } else {
              return "-";
            }
          },
        },
      ]
    },

    rowsPerPage: {
      set(limit) {
        if (this.$route.name !== 'Search')
          this.setLimit(limit)
        else 
          this.setSearchLimit(limit)
      },
      get() {
        if (this.$route.name !== 'Search')
          return this.filter.limit;
        else  
          return this.filter.searchLimit;
      }
    },
  },
  mounted() {
    this.$hubEventBus.$on("shipping-info", this.handleShippingInfoResponse);
  },
  beforeDestroy() {
    this.$hubEventBus.$off("shipping-info", this.handleShippingInfoResponse);

    this.filterModel.reset()
    this.setData({ filterData: {} })
  },
  methods: {
    ...mapActions({
      getShippingsList: "shippingsModule/getShippingsList",
      getShippingsSettings: "shippingsModule/getShippingsSettings",
      generateLabel: "shippingsModule/generateLabel",
      deleteShipping: "shippingsModule/deleteShipping",
      getResellersList: "providers/getResellersList",
      cancelShipping: "shippingsModule/cancelShipping",
    }),
    ...mapMutations({
      setData: "shippingsModule/setData",
      setLimit: "shippingsModule/setLimit",
      setSearchLimit: "shippingsModule/setSearchLimit",
      setFilterPage: "shippingsModule/setFilterPage",
      sortColumn: "shippingsModule/sortColumn",
      billsSetData: "clientBillsModule/setData",
      transactionsSetData: "transactionsModule/setData",
      clientActivitySetData: "clientActivityModule/setData",
      filterShippings: "shippingsModule/filterShippings",
      shippingsResetState: "shippingsModule/resetState"
    }),

    generateLabelHandler(id) {
      const toastOptions = {
        position: "toast-bottom-right",
        defaultTimeout: 500,
      };
      this.generateLabel(id)
        .then((data) => {
          toastOptions.msg = "generation in process";
          toastOptions.type = "success";
          toastOptions.style = {
            backgroundColor: "#01B2B8",
            width: "max-content",
            textTransform: "capitalize",
            opacity: "1",
          };
          this.$toastr.Add(toastOptions);
        })
        .catch((error) => {
          toastOptions.msg = "error on label generate";
          (toastOptions.type = "error"),
            (toastOptions.style = {
              backgroundColor: "red",
              width: "max-content",
              textTransform: "capitalize",
              opacity: "1",
            });
          this.$toastr.Add(toastOptions);
        });
    },

    async handleShippingInfoResponse(data) {
      await this.getShippingsList();
      this.resetSelectedList()
    },

    async selectAction(event, data, actionName) {
      this.setData({ selectedShippingtId: data.rowData.id });

      switch (actionName) {
        case "delete":
          {
            this.warningModalBtnText = "Delete";
            this.warningModalType = "delete";
            this.warningModalIsDangerButton = true;
            this.setData({ showWarningModal: true });
          }
        case "cancel":
          {
            this.warningModalBtnText = "Cancel";
            this.warningModalType = "cancel";
            this.warningModalIsDangerButton = true;
            this.setData({ showWarningModal: true });
          }
          break;
      }
    },

    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getShippingsList()
      this.createColumnConfigData()
      this.resetSelectedList()
    },

    async resetData() {
      this.filterModel.reset();
      await this.getShippingsList(this.shippings.filterData);
      this.resetSelectedList()
    },

    async sortColumnData(data) {
      this.sortColumn(data);
      await this.getShippingsList(this.shippings.filterData);
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })

      this.sortColumn();
      await this.getShippingsList(this.shippings.filterData);
      this.resetSelectedList()
    },

    async updateShippings(page) {
      if (page) {
        this.setFilterPage(page);
      }
      this.sortColumn();
      await this.getShippingsList(this.shippings.filterData);
      this.resetSelectedList()
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;

      switch (this.warningModalType) {
        case "delete":
          await this.deleteShipping(this.selectedShippingtId)
            .then(() => this.getShippingsList(this.shippings.filterData))
            .finally(() => {
              this.warningModalIsLoading = false;
              this.setData({ showWarningModal: false });
            });
        case "cancel":
          await this.cancelShipping(this.selectedShippingtId)
            .then(() => this.getShippingsList(this.shippings.filterData))
            .finally(() => {
              this.warningModalIsLoading = false;
              this.setData({ showWarningModal: false });
            });
          break;
      }

      this.resetSelectedList()
    },

    openShippingModal(shipping) {
      this.setData({ selectedShippingtId: shipping.id });

      let keys = {};

      // Shipping From Information
      if (shipping?.shipFrom) {
        // all camelCase keys change to lowDash
        for (const [key, value] of Object.entries(shipping.shipFrom)) {
          if (
            key !== "providerId" &&
            key !== "id" &&
            key !== "isBilling" &&
            key !== "inUse" &&
            key !== "isMain" &&
            key !== "isForShipping" &&
            key !== "forInvoice" &&
            key !== "isWarehouse" &&
            key !== "createdAt" &&
            key !== "updatedAt"
          ) {
            const lowDashKey = key
              .replace(/([a-z])([A-Z])/g, "$1_$2")
              .toLowerCase();
            keys[lowDashKey] = value;
          }
        }

        keys = {
          phone: keys.phone,
          company_name: keys.company_name,
          office_name: keys.office_name,
          company: keys.company,
          address: keys.address,
          unit: keys.unit,
          city: keys.city,
          zip: keys.zip,
          state: keys.state,
          country: keys.country,
          accept_self_pickup: keys.accept_self_pickup,
          accept_currier_pickup: keys.accept_currier_pickup,
          email: keys.email
        };

        this.selectedInformations.push({
          heading: "Ship From Information",
          keys: { ...keys },
        });

        keys = {};
      }

      // Destination Information
      if (shipping?.easyShipData?.destination_address) {
        this.selectedInformations.push({
          heading: "Destination Information",
          keys: { ...shipping.easyShipData.destination_address },
        });
      }

      // Courier Information
      if (shipping?.selectedCourier) {
        keys = {};

        for (const [key, value] of Object.entries(shipping.selectedCourier)) {
          if (
            key !== "courier" &&
            key !== "courier_id" &&
            key !== "rates_in_origin_currency"
          ) {
            keys[key] = value;
          }
        }
        this.selectedInformations.push({
          heading: "Courier Information",
          keys: { ...keys },
        });

        keys = {};
      }

      // Order Data
      if (shipping?.easyShipData?.order_data) {
        this.selectedInformations.push({
          heading: "Order Data",
          keys: { ...shipping.easyShipData.order_data },
        });
      }

      // Insurance
      if (shipping?.easyShipData?.insurance) {
        this.selectedInformations.push({
          heading: "Insurance",
          keys: { ...shipping.easyShipData.insurance },
        });
      }

      // Traking Information
      if (shipping?.trackings) {
        this.selectedInformations.push({
          heading: "Traking Information",
          keys: { ...shipping?.trackings[0] },
        });
      }

      // Shipment Details
      if (shipping?.boxes && shipping?.equipments && shipping?.easyShipData) {
        this.selectedInformations.push({
          heading: "Shipment Details",
          keys: {
            box_count: shipping.boxes.length,
            total_actual_weight: shipping?.boxes[0].weight.toFixed(3) + " lb",
            total_chargeable_weight:
              shipping?.boxes[0].weight.toFixed(3) + " lb",
            total_item_count: shipping?.equipments?.length,
            total_selling_price: `
              ${
                typeof shipping?.easyShipData?.total_customs_value === 'number'
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(shipping?.easyShipData.total_customs_value)
                  : '-'
              }
            `
          },
        });
      }

      // Shipment Costs
      let shipmentCost =
          shipping?.selectedCourier?.rates_in_origin_currency
            ?.shipment_charge_total,
        insuranceFee = shipping?.selectedCourier?.insurance_fee,
        totalCost =
          shipping?.selectedCourier?.rates_in_origin_currency?.total_charge;

      this.selectedInformations.push({
        heading: "Shipment Costs",
        keys: {
          shipment_cost:  new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          }).format(shipmentCost),
          insurance_fee:  new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          }).format(insuranceFee),
          total_cost: new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                      }).format(totalCost),
        },
      });

      this.selectedShipping = { ...shipping };
      this.isOpenShippingModal = true;
    },

    async copyTrackingLink() {
      this.trackingUrlCopied = true;
      try {
        await navigator.clipboard
          .writeText(this.selectedShipping?.easyShipData?.tracking_page_url)
          .then(() => (this.trackingUrlCopied = true))
          .finally(() => {
            setTimeout(() => {
              this.trackingUrlCopied = false;
            }, 500);
          });
      } catch (err) {
        console.error("Error copying text: ", err);
      }
    },

    closeShippingModal() {
      this.isOpenShippingModal = false;
      // setTimeout(() => {
      this.selectedShipping = null;
      this.setData({ selectedShippingtId: null });
      this.selectedInformations = [];
      // },200)
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setData({ showWarningModal: false });
    },

    hoverColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: false })
        this.billsSetData({ showColumnConfig: false })
        this.clientActivitySetData({ showColumnConfig: false })
      }
    },

    leaveColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: true })
        this.billsSetData({ showColumnConfig: true })
        this.clientActivitySetData({ showColumnConfig: true })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.p-l-10 {
  padding-left: 10px;
}

.v-menu__content {
  max-width: 190px !important;
}
.approve-rej-icon-cont {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1.5px solid #a5aac6;
}

.P-small-header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.w-31 {
  width: 31.5%;
}

.w-64 {
  width: 64.5%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.txt-14 {
  font-size: $txt14;
}

.txt-13 {
  font-size: 13px;
}

.P-shipping-modal {
  & .info-cont {
    border: 1px solid $neutral-color45;
    border-radius: 2px;
    margin-bottom: 1.5%;

    &__insurance {
      font-size: 11.5px;
      font-weight: bold;
      color: white;
      border-radius: 20px;
      padding: 1px 5px;
    }

    & table {
      border-collapse: collapse;
    }

    &__costs-table {
      border-collapse: collapse;
      tr {
        border: 1px solid $neutral-color45;
      }

      & td:nth-child(1) {
        padding-left: 200px;
      }

      & td:nth-child(2) {
        padding-left: 70px;
      }
    }

    &__parcels-table {
      & > tr:nth-child(2) {
        td {
          vertical-align: middle;
        }
      }

      & .description-column {
        width: 320px;
      }
    }
  }

  & tr {
    vertical-align: baseline;
  }

  & .bg-neutral {
    background: $neutral-color15;
  }
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 0;
  }

  .P-courier-data,
  .P-sender-data {
    justify-content: flex-start !important;
  }
}

</style>

<style lang="scss">
.shippings {
  & .small-logo {
    width: 32px !important;
    height: 24px !important;
    object-fit: cover;
    border-radius: 4px !important;
  }

  & .shipping-status-circle {
    width: 14px !important;
    height: 14px !important;
    border-radius: 50% !important;
  }

  & .shipping-status-text {
    font-size: 12.5px !important;
    font-weight: bold !important;
  }

  & .background-red {
    background: #fc716d !important;
  }

  & .background-black {
    background: #454f5b !important;
  }

  & .background-green {
    background: #50d4a4 !important;
  }

  & .background-orange {
    background: #ff6400 !important;
  }

  & .color-white {
    color: white !important;
  }

  & .return-label {
    p {
      font-size: 12px !important;
      font-weight: bold !important;
    }
  }
}
</style>
