<template>
  <div>
    <div class="P-global-search-cont">
      <template v-if="showSearchComponents">
        <draggable
          :list="dragComponents"
          :animation="0"
          :disabled="lockDrags"
          class="list-group"
          ghost-class="ghost"
          group="description"
          @start="dragging = true"
          @end="dragged"
        > 
            <!-- v-if="
                (component && component?.name !== 'ClientShippings' && component?.name !== 'ClientPaymentMethod') ||
                (
                  component && component?.name === 'ClientPaymentMethod' && 
                  (paymentList.length || clientData.provider.hasValidPaymentGateway)
                ) ||
                (component && component?.name === 'ClientShippings' && clientData.provider.hasValidShippingGateway)

              " -->

          <div
            v-for="(component, key) of dragComponents"
            :key="component?.name ? component?.name : key"
            :class="{'overflow-hidden': component?.name !== 'ClientCommentsList'}"
            :style="{width: tabsWidth+'%'}"
            class="search-cont-item position-relative"
          > 
            <component :is="component?.name" :ref="component?.ref" />
          </div>
        </draggable>
      </template>

      <page-loader v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { eventBus } from "@/main";
import ClientContact from "@/views/clients/modals/manageClient/contact/Contact";
import ClientAddress from "@/views/clients/modals/manageClient/address/Address";
import ClientPaymentMethod from "@/views/clients/modals/manageClient/paymentMethods/PymentMethods";
import ClientBalanceCredit from "@/views/clients/modals/manageClient/balanceCredit/BalanceCredit";
import ClientLocations from "@/views/clients/modals/manageClient/location/Location";
import ClientUsedDevices from "@/views/clients/modals/manageClient/usedDevices/UsedDevices";
// import ClientProfile from "@/views/clients/modals/manageClient/profile/Provile";
import ClientPackages from "@/views/clients/modals/manageClient/packages/Packages";
import ClientShippings from "@/views/shippings/Shippings";
import ClientActivity from "@/views/client-activity/ClientActivity";
import ClientBillsList from "@/views/client-bills/ClientBills";
import ClientTransactionsList from "@/views/transactions/Transactions";
import ClientCommentsList from "@/views/clients/modals/manageClient/comments/Comments";
import PageLoader from "@/components/pageLoader/PageLoader";

import Draggable from "vuedraggable";
import HubMixin from "@/mixins/Hub.mixin.js";

export default {
  name: "GeneralSearch",
  components: {
    ClientContact,
    ClientAddress,
    ClientPaymentMethod,
    ClientBalanceCredit,
    ClientLocations,
    ClientUsedDevices,
    ClientPackages,
    ClientShippings,
    ClientActivity,
    ClientBillsList,
    ClientTransactionsList,
    ClientCommentsList,
    PageLoader,
    Draggable,
  },

  data() {
    return {
      dragging: false,
    };
  },
  mixins: [HubMixin],
  computed: {
    ...mapState({
      showSearchComponents: (state) => state.generalSearch.showSearchComponents,
      hubState: (state) => state.appModule.hubState,
      clientDragComponents: (state) => state.generalSearch.clientDragComponents,
      userDragComponents: (state) => state.generalSearch.userDragComponents,
      providerDragComponents: (state) =>
        state.generalSearch.providerDragComponents,
      paymentList: (state) => state.paymentMethodClients.paymentList,
      addMode: (state) => state.paymentMethodClients.addMode,
      isSaved: (state) => state.appModule.isSaved,
      isAddClient: (state) => state.clientsModule.isAddClient,
      isAddClientFromSearch: (state) => state.clientsModule.isAddClientFromSearch,
      lockDrags: (state) => state.generalSearch.lockDrags,
      tabsWidth: (state) => state.generalSearch.tabsWidth,
      clientData: (state) => state.clientsModule.client,
      clientId: (state) => state.clientsModule.clientId,
      clientProviderId: (state) => state.clientsModule.clientProviderId,
      paymentForPackagesObj: (state) => state.financeClient.paymentForPackagesObj,
      isOpenClientModal: (state) => state.clientsModule.modal.isOpen,
    }),

    searchId() {
      return this.$route.params.id;
    },

    searchType() {
      return this.$route.params.type;
    },

    dragComponents() {
      switch (this.searchType) {
        case "client":
          return this.clientDragComponents;
          break;
        case "user":
          return this.userDragComponents;
          break;
        case "provider":
          return this.providerDragComponents;
          break;
        default:
          return this.clientDragComponents;
          break;
      }
    },
    handleClientSocket(data) {
      console.log(`client-${this.socketClientId} socket data arrived`, data);
    }
  },

  watch: {
    hubState() {
      if (this.socketClientId && this.hubState === 5) {
        this.subscribeHubGroup(null, `client-${this.socketClientId}`, this.handleClientSocket);
      }
    },
    async searchId(newVal, oldVal) {
      this.setMenuActiveIndex(null)
      this.resetStates()

      this.setData({ showSearchComponents:  false })
      this.setClientCreditAmount(0)
        await Promise.all([
          this.getDragStatuses(), // get drag saved drag status
          this.getServerList(), // get server list for add location
        ])

      if (this.searchType === "client" && this.searchId) {
        this.setData({ 
          searchedClientId: this.searchId,      
          isGeneralSearch: true
        });
        
        this.clientSetData({
          clientId: this.$route.params.id,
          isAddClient: false,
        });

        await Promise.all([
          this.getClientAllData(), 
          this.getCurrencyList(), // get currency list for finance currency
          this.getPaymentList(), // get payment list for finance payment
          this.getResellersList() // get resellers list for transactions, bills payment
        ])
      }
      this.setData({ showSearchComponents:  true })

    },

    isOpenClientModal(newVal, oldVal) {
      if (!newVal) {
        this.getClientAllData()
      }
    },

    isSaved(newVal) {
      const toastOptions = {
        msg: 'The request has been saved successfully',
        position: "toast-bottom-right",
        defaultTimeout: 500,
        style: { 
          backgroundColor: "#01B2B8", 
          width: "max-content", 
          textTransform: "capitalize",
          opacity: '1'
        }
      }

      if (newVal && !this.isAddClient) this.$toastr.Add(toastOptions);
    }
  },

  mounted() {
    this.socketClientId = this.$route.params['id'];
    this.subscribeHubGroup(`client-${this.socketClientId}`, `client-${this.socketClientId}`, this.handleClientSocket);
  },

  beforeDestroy() {
    this.subscribeHubGroup(`client-${this.socketClientId}`, null, this.handleClientSocket);
  },

  async created() {
    this.setMenuActiveIndex(null)
    this.resetStates()

    this.setData({ showSearchComponents:  false })
    await Promise.all([
      this.getDragStatuses(), // get drag saved drag status
      this.getServerList() // get server list for add location
    ])

    if (this.searchType === "client" && this.searchId) {
      this.setData({ 
        searchedClientId: this.searchId,      
        isGeneralSearch: true
      });
      
      this.clientSetData({
        clientId: this.$route.params.id,
        isAddClient: false,
      });
      
      await Promise.all([
        this.getClientAllData(),     
        this.getCurrencyList(), // get currency list for finance currency
        this.getPaymentList(), // get payment list for finance payment
        this.getResellersList() // get resellers list for transactions, bills payment
      ])
    }

    // eventBus.$on('clientModalCreated', async () => {
    //   let isAddClient = this.isAddClient
    //   if (isAddClient) {
    //     this.setData({ showSearchComponents:  false })
    //   }
    // }) 
    
    eventBus.$on('clientModalDestroyed', async () => {
      this.clientSetData({
        clientId: this.$route.params.id,
      });

      if (this.isAddClientFromSearch) {
        this.setData({ showSearchComponents:  false })

        this.clientSetData({
          isAddClient: false,
        });

        await this.getClientAllData();
        this.setData({ showSearchComponents:  true })
      }

      this.clientSetData({
        isAddClient: false,
        isAddClientFromSearch: false
      });
    })

    // After updated(edit) payment method updated client data
    eventBus.$on('paymentMethodUpdated', async () => {
      await this.getClientAllData();
    })

    // After deleted payment method updated client data
    eventBus.$on('paymentMethodDeleted', async () => {
      await this.getClientAllData();
    })
      

    this.setData({ showSearchComponents:  true })
  },

  destroyed() {
    this.setData({ searchedClientId: null });

    this.setData({
      isGeneralSearch: false
    })

    this.clientSetData({
      clientId: null,
    });

    this.billsSetData({
      clientBills: [],
    });

    this.transactionsSetData({
      clientTransactions: [],
    });
  },

  methods: {
    handleSocketConnected() {
    },
    ...mapActions({
      getDragStatuses: "generalSearch/getDragStatuses",
      getClientData: "clientsModule/getClientData",
      getServerList: "clientsModule/getServerList",
      getClientPaymentMethod: "paymentMethodClients/getPaymentMethod",
      getClientBalanceCredit: "balanceCreditClient/getBalanceCredit",
      getClientLocations: "locationClient/getLocations",
      setClientAddresses: "addressClient/setAddresses",
      setClientContactData: "contactClient/setContactData",
      updateDragStatus: "generalSearch/updateDragStatus",
      getBillsList: "clientBillsModule/getBillsList",
      getTransactionList: "transactionsModule/getTransactionList",
      getComments: "comments/getComments",
      getCurrencyList: 'financeClient/getCurrencyList',
      getPaymentList: 'financeClient/getPaymentList',
      getShippingsList: "shippingsModule/getShippingsList",
      getResellersList: 'providers/getResellersList',
    }),

    ...mapMutations({
      setData: "generalSearch/setData",
      clientSetData: "clientsModule/setData",
      clientContactSetData: "contactClient/setData",
      financeSetData: "financeClient/setData",
      setFinanceFormData: "financeClient/setFormData",
      setClientPersonalInfo: "personalInfoClient/setPersonalInfoData",
      setClientAdditionalInfo: "personalInfoClient/setAdditionalInfoData",
      billsSetData: "clientBillsModule/setData",
      transactionsSetData: "transactionsModule/setData",
      clientPaymentsData: "paymentMethodClients/setData",
      setClientCreditAmount: "balanceCreditClient/setCreditAmount",
      setMenuActiveIndex: "sidebar/setActiveIndex",

      // Reset State mutations
      contactResetState: 'contactClient/resetState',
      addressesResetState: 'addressClient/resetState',
      paymentMethodsResetState: 'paymentMethodClients/resetState',
      balanceCreditResetState: 'balanceCreditClient/resetState',
      financeResetState: "financeClient/resetState",
      locationsResetState: 'locationClient/resetState',
      usedDevicesResetState: 'usedDevicesClient/resetState',
      packagesClientsResetState: 'packagesClients/resetState',
      shippingResetState: 'shippingsModule/resetState',
      clientActivityResetState: 'clientActivityModule/resetState',
      clientBillsResetState: 'clientBillsModule/resetState',
      transactionsResetState: 'transactionsModule/resetState',
      commentsResetState: 'comments/resetState', 
      equipmentsResetState: "equipments/resetState",
    }),

    resetStates() {
      this.contactResetState()
      this.addressesResetState()
      this.paymentMethodsResetState()
      this.balanceCreditResetState()
      this.financeResetState()
      this.locationsResetState()
      this.usedDevicesResetState()
      this.packagesClientsResetState()
      this.shippingResetState()
      this.clientActivityResetState()
      this.clientBillsResetState()
      this.transactionsResetState()
      this.commentsResetState()
    },

    async getClient(clientId) {
      if (clientId) {
        // this.usedDevicesResetState() //check
        this.equipmentsResetState()
        this.clientContactSetData({
          emailSection: true,
          phoneSection: true,
          isEditMode: false,
        });
        this.clientSetData({ clientProviderId: null })

        await this.getClientData().then((r) => {
          this.setData({ hasValidTwilio: r?.provider?.hasValidTwilio})
          this.clientSetData({ clientProviderId: r?.provider?.id })
          this.setClientAdditionalInfo(r); // for personal info tab
          this.setClientPersonalInfo(r?.personalInfo); // for personal info tab
          this.setClientContactData({ emails: r?.emails, phones: r?.phones }); // for contact tab
          if (r.addresses.length) {
            this.setClientAddresses({ addresses: r?.addresses });
          } // for addresses tab

          /*For Finance tab set formData*/
            let finance = {
              currency: null,
              priceGroup: null,
              paymentForPackages: null,
              paymentForDevice: null,
              isPaperlessBilling: false
            } // TODO hardcoded.

            this.setFinanceFormData(finance)

            let paymentForPackagesObj = null
            r.finance ? finance = { ...r.finance } : this.financeSetData({ showFinanceForm: true })
            paymentForPackagesObj = r.finance?.forPackages
            
            this.setFinanceFormData(finance)
            this.financeSetData({ paymentForPackagesObj })
            /********************************/
        });

        /** for Payment method, Balance/credit, Location tabs, Transactions, Bills, Shippings, Comments **/
        await Promise.all([
          this.getClientPaymentMethod({ clientId }),
          this.getClientBalanceCredit(),
          this.getClientLocations(),
          this.getTransactionList(),
          this.getBillsList(),
          this.getShippingsList(),
          this.getComments(this.searchId)
        ]);

        this.setDefaultPaymentMethod()
      }
    },
    async dragged() {
      this.dragging = false;

      await this.updateDragStatus({
        searchType: this.searchType,
        dragComponents: this.dragComponents,
      });
    },

    async getClientAllData() {
      if (this.searchId) {
        await this.getClient(this.searchId);
        // await this.getComments(this.searchId);
      }
    },

    setDefaultPaymentMethod() {
      // Set Default Payment Method
      if (this.paymentList.length) {
        this.paymentList.map(item => {
          if (item.default) {
            this.clientPaymentsData({ selectedCreditCard: item.id })
          }
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";

.P-global-search-cont {
  padding: 8px;

  .list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .search-cont-item {
      box-shadow: 0 0 2px 0 rgb(172, 172, 172);
      padding: 12px;
      margin: 0.8%;
      border-radius: 4px;
      transition: all 0.9s;
      // overflow: hidden;
    }
  }
}

.theme--light {
  .search-cont-item {
    background: white;
  }
}

.theme--dark {
  .search-cont-item {
    background: $dark_light;
  }
}

// draggable style
.ghost {
  opacity: 0.5;
  background: rgba($color: #fff, $alpha: 0.3);
}
.no-move {
  transition: transform 0s;
}
</style>