<template>
  <div class="P-comments-part">
    <div class="P-form-part">
      <quill-editor v-model="comment" ref="myQuillEditor" :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"></quill-editor>
      <ott-switch v-model="sendNotification" label="Send Notification" customSwitchClass="mt-3 mb-2" />

      <transition name="slide">
        <div v-if="sendNotification">
          <div class="G-flex-wrap G-align-center">
          <div
              class="pa-2 ps-0 ma-0 G-date-picker P-remainder-date G-flex G-align-center"
            >
              <DatePicker
                v-model="reminderDate"
                :masks="{ input: ottDateFormat }"
                :min-date="newDateWithMainUserTZ"
                :popover="{ visibility: 'click' }"
                :is-dark="$vuetify.theme.dark"
                locale="en"
                class="ott-date-picker"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="G-calendar-input">
                    <v-text-field
                      :value="inputValue"
                      v-on="inputEvents"
                      :readonly="true"
                      :error="$v.formData.reminderDate.$error"
                      outlined
                      dense
                      height="38px"
                      label="Remainder Date"
                      class=""
                    />
                    <span
                      @click="reminderDate = null"
                      class="P-date-icon neutral--text"
                      :class="
                        !inputValue
                          ? 'mdi mdi-calendar-blank-outline neutral--text P-calendar'
                          : 'icon-cancel'
                      "
                    ></span>
                  </div>
                </template>
              </DatePicker>
            </div>
            <div
              class="pa-2 ma-0 G-flex-center">
              <ott-input
                v-model="time"
                :error="$v.formData.time.$error"
                customInputClass="time-inp me-2"
                format="hh:mm A"
                max='12:59'
                min='01:00'
                type="time"
                label="Time"
              ></ott-input>

              <v-radio-group
                v-model="ampm"
                @change="amPmChange"
                class="pt-0"
              >
                <div class="G-justify-between flex-column">
                  <div class="P-radio-box">
                    <ott-radio
                      value="am"
                      label="am"
                    />
                  </div>
                  <div class="P-radio-box">
                    <ottRadio
                      value="pm"
                      label="pm"
                    />
                  </div>
                </div>
              </v-radio-group>
            </div>
          </div>
          <ott-switch v-model="isPrivate" label="Private Notification" />
        </div>
      </transition>

      <div class="P-save-btn-block mt-2">
        <save-reset-buttons
          :left-click-func="() => $emit('cancelAddEdit')"
          :right-click-func="save"
          :is-loading="isLoading"
          :right-label="!isEdit ? 'Add' : 'Edit'"
          left-label="Cancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";

import DatePicker from "v-calendar/src/components/DatePicker";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import SaveResetButtons from "@/components/SaveResetButtons";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";

export default {
  name: "Comments",
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  data() {
    return {
      hours: '',
      minutes: '',
      ampm: null,
    }
  },
  validations: {
    formData: {
      reminderDate: { 
        required: requiredIf(function () {
          return this.sendNotification;
        }),
      },
      time: {
        required: requiredIf(function () {
          return this.sendNotification;
        }),
      }
    }
  },
  components: {
    DatePicker,
    VueTimepicker,
    OttButtonLoader,
    OttTextarea,
    OttSwitch,
    SaveResetButtons,
    OttInput,
    OttRadio,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.appModule.isLoading,
      formData: (state) => state.comments.formData,
      searchedClientId: (state) => state.generalSearch.searchedClientId,
      commentsList: (state) => state.comments.commentsList,
      tabsWidth: (state) => state.generalSearch.tabsWidth,
    }),

    comment: {
      get() {
        return this.formData.comment;
      },
      set(comment) {
        this.setFormData({ comment });
      },
    },

    sendNotification: {
      get() {
        return this.formData.sendNotification;
      },
      set(value) {
        this.setFormData({ sendNotification: !value ? false : true });
      },
    },

    reminderDate: {
      get() {
        return this.formData.reminderDate;
      },
      set(reminderDate) {
        this.setFormData({ reminderDate });
      },
    },

    isPrivate: {
      get() {
        return this.formData.isPrivate;
      },
      set(value) {
        this.setFormData({ isPrivate: !value ? false : true });
      },
    },

    time: {
      get() {
        return this.formData.time;
      },
      set(time) {
        this.setFormData({ time });
      },
    },
  },

  mounted() {
    if (this.isEdit) {
      console.log(this.reminderDate, 'reminderDate')
      this.reminderDate = new Date(this.reminderDate)
    } else {
      this.reminderDate = this.newDateWithMainUserTZ
      // if (typeof this.newDateWithMainUserTZ === 'string') {
      //   this.time = this.$moment(this.newDateWithMainUserTZ).format('HH') + ":" + this.$moment(this.newDateWithMainUserTZ).format('mm')
      // }
    }

    this.ampm = this.$moment(this.reminderDate).format('a')
    this.time = this.$moment(this.reminderDate).format('HH') + ":" + this.$moment(this.reminderDate).format('mm')

  },

  destroyed() {
    this.resetFormData()
  },

  methods: {
    ...mapActions({
      addComment: "comments/addComment",
      editComment: "comments/editComment",
    }),
    ...mapMutations({
      setFormData: "comments/setFormData",
      resetFormData: "comments/resetFormData",
    }),

    amPmChange(type) {
      let hours = this.time .split(':')[0]
      let minutes = this.time .split(':')[1]

      if (type === 'pm') {
        hours = this.convert12HourTo24Hour(`${hours}:${minutes}`).split(':')[0]
        minutes = this.convert12HourTo24Hour(`${hours}:${minutes}`).split(':')[1]
      } else {
        hours = this.convert24HourTo12Hour(`${hours}:${minutes}`).split(':')[0]
        minutes = this.convert24HourTo12Hour(`${hours}:${minutes}`).split(':')[1]
      
        if (hours === '12') {
          hours = '00'
        }
      }

      this.time = `${hours}:${minutes}`
      this.reminderDate = this.$moment(this.reminderDate).set({ hours, minutes }).format('MM/DD/YYYY HH:mm');
    },

    async save() {
      const data = {
        client: this.searchedClientId,
        comment: this.comment,
        sendNotification: this.sendNotification,
      };

      if (this.sendNotification) {
        this.$v.formData.$touch();
        let reminderDate = this.$moment(this.reminderDate);

        if (!this.$v.formData.$error) {
          this.hours = this.time.split(':')[0]
          this.minutes = this.time.split(':')[1]

          reminderDate.set({
            h: this.hours,
            m: this.minutes,
          });

          data.reminderDate = reminderDate.format('MM/DD/YYYY HH:mm');
          data.isPrivate = this.isPrivate;
        }
      }
      
      if (this.comment) {
        !this.isEdit ? await this.addComment(data) :  await this.editComment(data);
      }

      this.$emit("isSaved");
    },

    convert12HourTo24Hour(time12h) {
      const [hours, minutes] = time12h.split(':');
      let hours24h = parseInt(hours, 10);

      // If the input time is 12:xx, it remains the same in 24-hour format
      if (hours24h === 12) {
        return `${hours}:${minutes}`;
      }

      // If the input time is 1:xx to 11:xx, add 12 to the hours
      if (hours24h >= 1 && hours24h <= 11) {
        hours24h += 12;
      }

      // Format hours and minutes as a 24-hour time string
      const formattedHours = String(hours24h).padStart(2, '0');
      const formattedMinutes = minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },

    convert24HourTo12Hour(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(":");

      // Convert hours and minutes to numbers
      const hoursNum = parseInt(hours, 10);
      const minutesNum = parseInt(minutes, 10);

      // Convert hours to 12-hour format
      const hours12 = hoursNum > 12 ? hoursNum - 12 : hoursNum === 0 ? 12 : hoursNum;

      // Ensure leading zeros for hours and minutes
      const hoursStr = hours12 < 10 ? `0${hours12}` : `${hours12}`;
      const minutesStr = minutesNum < 10 ? `0${minutesNum}` : `${minutesNum}`;

      // Create the 12-hour time string
      const time12 = `${hoursStr}:${minutesStr}`;

      return time12;
    }
  },
};
</script>

<style lang="scss" scoped>
.P-comments-part {
  .v-text-field__details {
    display: none;
  }
}

::v-deep {
  .v-text-field__details {
    display: none;
  }

  .ql-snow .ql-tooltip {
    z-index: 1;
  }
}

.P-remainder-date {
  width: 200px;
}

.w-35 {
  width: 35%;
}

.ott-date-picker {
  width: 100%;
}
</style>

<style >

.time-inp input {
  width: 42px !important;
}
</style>