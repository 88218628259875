<template>
  <selected-filters
    :selected-list="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  components: { SelectedFilters },
  mixins: [DateFormatMixin],
  computed: {
    ...mapState({
      filterModel: (state) => state.shippingsModule.filterModel,
      filter: (state) => state.shippingsModule.filterData,
      shippingsList: (state) => state.shippingsModule.dataTable,
      resellersList: (state) => state.providers.resellersList,   
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `
            <div class="d-flex align-center">
              <span class="me-1"><b>Search</b>:</span>
              ${this.filterModel.search}
          `,
          resetArgument: "search",
        },
        {
          ifCondition: this.filterModel.allResellers,
          boxText: "<b>All</b>: Yes",
          resetArgument: "allResellers",
        },
        {
          ifCondition: this.filterModel.resellers.length,
          boxText: "<div class='G-flex-center'><span class='me-2'><b>Resellers</b>:</span> " + this.resellersNames,
          resetArgument: "resellers",
        },
        {
          ifCondition: !!this.filterModel.courier,
          boxText: `<div class="d-flex align-center pa-1">
              <span class="me-1"><b>Courier</b>:</span>
              ${
                this.courierLogo
                  ? `<img src="https://assets.easyship.com/app/courier-logos/${this.courierLogo}-mini.svg" class="small-logo me-1"/>`
                  : ""
              }  ${this.courierName}
            </div>`,
          resetArgument: "courier",
        },
        {
          ifCondition:
            this.filterModel.date?.start && this.filterModel.date?.end,
          boxText: `
          <div class="d-flex align-center">
            <span class="me-1"><b>Shipping Date</b>:</span>
            ${this.getDate(this.filterModel.date?.start)} - 
            ${this.getDate(this.filterModel.date?.end)}
          </div>`,
          resetArgument: "date",
        },
      ];
    },

    resellersNames() {
      let providersNames = '<span>'
      if (this.resellersList.length) {
        this.resellersList.map((i) => {
          this.filterModel.resellers.map((j, index) => {
            if(i.id === j) {
              providersNames += `${i.name}${this.filterModel.resellers.length > 1 ? '<br />' : ''}`
            }
          })
        })
      }
      providersNames += '</span>'

      return providersNames;
    },

    courierName() {
      let name = "";

      this.shippingsList.map((item) => {
        if (item?.selectedCourier) {
          if (item.selectedCourier.courier_id) {
            if (item.selectedCourier.courier_id === this.filterModel.courier) {
              name = item.selectedCourier.courier_name;
            }
          }
        }
      });

      return name;
    },

    courierLogo() {
      let logo = "";

      this.shippingsList.map((item) => {
        if (item?.selectedCourier) {
          if (item.selectedCourier.courier_id) {
            if (item.selectedCourier.courier_id === this.filterModel.courier) {
              logo = item.selectedCourier?.courier?.logo_url;
            }
          }
        }
      });

      return logo;
    },
  },

  methods: {
    ...mapActions({
      getShippingsList: "shippingsModule/getShippingsList",
    }),

    ...mapMutations({
      filterShippings: "shippingsModule/filterShippings",
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterShippings();
      await this.getShippingsList(this.filter);
    },

    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },
  },
};
</script>
